* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  text-align: right;
  font-family: 'Arimo', sans-serif;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /* background-image: linear-gradient(120deg, #e9e9e950 0%, #e3f0f65e 100%); */
  /* background-color: #f5f5f583; */
  /* background-color: #fff; */
  background-color: aliceblue;
}

.app-container {
  display: grid;
  grid-template-rows: 4.2rem auto 20rem;
  grid-template-areas: 'header' 'main' 'footer';
  height: 100vh;
}

main {
  grid-area: main;
}

nav {
  grid-area: header;
}

footer {
  grid-area: footer;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Arimo', sans-serif;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

h2 {
  text-align: center;
}
