#SubTopicPage-title {
  margin-top: 50px;
}
.subTopicPage__section-desc {
  font-size: 1.4rem;
  text-align: center;
}

#topic-page p {
  width: 85%;
  line-height: 1.5;
}

#topic-page .subTopicPage__section-desc p {
  margin: 3rem auto;
}

.subTopicPage__section-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.subTopicPage__section-imgs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  /* align-items: start; */
}

.subTopicPage__img-container {
  overflow: hidden;
}

.subTopicPage__img {
  width: auto;
  height: 12.5rem;
  cursor: zoom-in;
  margin: 0.2rem;
  border-radius: 0.5rem;
  border: 1px solid black;
}

.subTopicPage__img:hover {
  transform: scale(1.02);
  border-radius: 0.5rem;
  transition: transform ease-out 250ms, border-radius ease-out 200ms;
  /* animation: flip-img 1s ease-out forwards; */
}

#polarsteps {
  text-align: center;
  margin: 80px auto 30px;
}

#polarsteps h3 {
  margin-bottom: 30px;
}

@media (max-width: 380px) {
  #SubTopicPage {
    width: 330px;
  }
  .subTopicPage__section-desc {
    font-size: 1.1rem !important;
    line-height: 1.25em;
  }
}

@media (max-width: 480px) and (min-width: 381px) {
  #SubTopicPage {
    width: 400px;
    padding-right: 0;
    padding-left: 0;
  }
  .subTopicPage__section-desc {
    font-size: 1.2rem !important;
    line-height: 1.25em;
  }
}
@media (max-width: 550px) and (min-width: 481px) {
  #SubTopicPage {
    width: 500px;
    padding-right: 0;
    padding-left: 0;
  }
  .subTopicPage__section-desc {
    font-size: 1.2rem !important;
    line-height: 1.25em;
  }
}

@media (max-width: 700px) and (min-width: 551px) {
  #SubTopicPage {
    width: 550px;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 480px) {
  #polarsteps iframe {
    width: 320px;
    height: 400px;
  }
}

@media (max-width: 600px) and (min-width: 481px) {
  #polarsteps iframe {
    width: 460px;
    height: 520px;
  }
}
@media (max-width: 768px) and (min-width: 601px) {
  #polarsteps iframe {
    width: 570px;
    height: 640px;
  }
}

@media (max-width: 900px) and (min-width: 769px) {
  #polarsteps iframe {
    width: 740px;
    height: 800px;
  }
}

@media (max-width: 1024px) and (min-width: 901px) {
  #polarsteps iframe {
    width: 870px;
    height: 800px;
  }
}

@media (max-width: 1199px) and (min-width: 1025px) {
  #polarsteps iframe {
    width: 1000px;
    height: 800px;
  }
}

@media (max-width: 1200px) {
  .subTopicPage__section-desc {
    font-size: 1.3rem;
    line-height: 1.25em;
  }
  .subTopicPage__section {
    margin-top: 3rem;
  }
  .subTopicPage__section-imgs {
    margin-top: 1rem;
  }
}

@media (max-width: 1450px) and (min-width: 1200px) {
  .subTopicPage__img {
    width: auto;
    height: 12rem;
    cursor: zoom-in;
    margin: 0.2rem;
  }
  .subTopicPage__section-desc {
    font-size: 1.3rem;
  }
  #polarsteps iframe {
    width: 1100px;
    height: 920px;
  }
}

@media (min-width: 1451px) {
  .subTopicPage__img {
    width: auto;
    height: 12rem;
    cursor: zoom-in;
    margin: 0.3rem;
  }
  .subTopicPage__section-desc {
    font-size: 1.4rem;
  }
  #polarsteps iframe {
    width: 1290px;
    height: 920px;
  }
  .subTopicPage__section-row {
    margin-top: 4rem;
  }
}
