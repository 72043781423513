.backdrop {
  position: fixed;
  /* display: none; */
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  /* width: 100vw;
  height: 100vh; */
  background: rgba(0, 0, 0, 0.7);
}

.modal__container {
  z-index: 200;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; */

  /* position: fixed;
	top: 15%;
	right: 0;
    left: 0; */
}

.modal__container .carousel-inner {
  display: inline;
}

.modal__container img {
  max-height: 80vh;
  max-width: 80vw;
}

/* The Close Button */
.close {
  z-index: 300;
  position: absolute;
  top: -4vh;
  right: -8vw;
  color: #f1f1f1;
  font-size: 45px;
  font-weight: bold;
  transition: 0.3s;
}

#contactCloseModal {
  right: -3vw;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.modal-form__wrapper {
  display: flex;
  width: 270px;
  height: 150px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.7);
  background: #fff;
  color: #000;
  z-index: 200;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;
}

.modal-form__content {
  line-height: 1.8;
  color: #141414;
  text-align: center;
  font-size: 1.1rem;
}

#modalCarouselControls .carousel-control-next {
  right: -10vw;
}
#modalCarouselControls .carousel-control-prev {
  left: -10vw;
}
