.navbar.scrolled {
  background: rgba(20, 30, 44, 0.9);
  transition: background 200ms;
}

.main-nav {
  font-size: 1.3rem;
  height: 4.2rem;
  background: linear-gradient(90deg, rgb(20, 30, 44) 0%, rgb(19, 35, 53) 100%);
}

.main-nav .navbar-brand {
  font-size: 1.7rem;
  padding-left: 4rem;
  margin-left: 1rem;
}

.main-nav li {
  display: flex;
  justify-content: center;
}

.main-nav a {
  color: #fff;
}

#navbarDropdown {
  width: -webkit-fill-available;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: rgba(20, 30, 44, 0.9);
}

.main-nav a:hover {
  text-decoration: none;
}

.dropdown .dropdown-menu {
  display: none;
  left: auto;
  min-width: -webkit-fill-available;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.dont-show {
  display: none;
}

.main-nav__items .dropdown-toggle::after {
  margin-right: 0.255em;
}

.main-nav__items .dropdown-menu {
  text-align: center;
  background-color: rgba(19, 35, 53, 0.95);
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(255, 255, 255);
}

@media (max-width: 991px) {
  .main-nav .nav-item:hover {
    color: rgba(20, 30, 44, 0.9);
    background-color: #fff;
  }
  .main-nav .nav-item:hover a {
    color: rgba(20, 30, 44, 0.9);
  }

  .navbar-expand-lg .navbar-collapse {
    text-align: center;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
    background-color: rgba(19, 35, 53, 0.95);
  }
  .dropdown:hover .dropdown-menu,
  .main-nav__items .dropdown-toggle::after {
    display: none;
  }
  .nav-item {
    padding: 0.7rem;
  }
  .main-nav .navbar-brand {
    font-size: 1.5rem;
  }
}

@media (max-width: 1201px) {
  .navbar {
    padding: 0 2rem;
  }
  .main-nav .navbar-brand {
    padding-left: 2rem;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .main-nav .navbar-brand {
    font-size: 1.5rem;
    padding-left: 3rem;
    margin: 0;
  }
  ul:first-of-type a {
    font-size: 1.1rem;
  }
}

@media (max-width: 390px) {
  .main-nav .navbar-brand {
    font-size: 1.2rem;
    padding-left: 0;
  }
}
