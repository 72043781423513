footer {
  color: white;
  /* text-align: center; */
  width: 100%;
  padding-top: 7rem;
  display: grid;
  /* grid-template-rows: auto 50px 800px; */
}

.page-footer__main {
  background-image: url('https://res.cloudinary.com/yonatan-memorial/image/upload/c_scale,f_auto,q_auto:low/v1620476690/General/bg-strip2-blue_lrgahk.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  /* background-size: auto; */
  background-size: cover;
  /* background-size: contain; */
}

.page-footer__img-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* text-align: bottom; */
}

.page-footer__text {
  padding: 1rem 0;
  text-align: center;
}

.newsletter {
  background-color: rgb(20, 29, 43);
}
